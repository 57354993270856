import { cn, Item } from '#app/utils/misc'
import { models } from '#app/utils/models.js'
import { Link, useNavigate } from '@remix-run/react'
import ButtonLink from './link-button.tsx'
import { type NavItem } from './sidebar-items-data.tsx'
import { Button } from './ui/button.tsx'
import { Icon } from './ui/icon.tsx'
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from './ui/select.tsx'
import { Separator } from './ui/separator.tsx'

function NewProjectButton() {
	return (
		<Link className="w-full" to="/projects/new">
			<Button className="w-full" variant="outline">
				New Project
			</Button>
		</Link>
	)
}

export function SidebarItems({
	allProjects,
	navItems,
	project,
	setSidebarOpen,
	tier,
}: {
	allProjects: Item[]
	navItems: NavItem[]
	project: Item | null
	setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>
	tier?: string
}) {
	const navigate = useNavigate()

	const handleProjectValueChange = (value: string) => {
		if (value === 'new') {
			setSidebarOpen(false)
			navigate('/projects/new')
		} else if (value === 'all') {
			setSidebarOpen(false)
			navigate('/dashboard')
		} else {
			setSidebarOpen(false)
			navigate(models.project.detailsUrl(value))
		}
	}
	return (
		<ul className="-mx-2 space-y-1">
			{project === null && <NewProjectButton />}
			{project && (
				<Select key="project" onValueChange={handleProjectValueChange}>
					<SelectTrigger className="">
						<SelectValue placeholder={project.name} />
					</SelectTrigger>
					<SelectContent>
						<SelectItem value="all">
							<Icon className="pb-0.5" name="home">
								All Projects
							</Icon>
						</SelectItem>
						<SelectItem value="new">
							<Icon className="pb-0.5" name="plus">
								New Project
							</Icon>
						</SelectItem>
						{allProjects.map(({ id, name }) => (
							<SelectItem key={id} value={id}>
								{name}
							</SelectItem>
						))}
					</SelectContent>
				</Select>
			)}
			{navItems
				.filter(
					(item) =>
						!(project === null && (item.inProject || item.name === 'sep')),
				)
				.map((item) =>
					item.href ? (
						<li key={item.name}>
							<Link
								className={cn(
									item.current
										? 'bg-accent text-foreground'
										: 'text-foreground hover:bg-accent',
									'group flex gap-x-3 rounded-md p-2 text-sm leading-6',
								)}
								to={item.href}
							>
								{item.icon && (
									<Icon
										aria-hidden="true"
										className=" size-6 shrink-0"
										name={item.icon}
									/>
								)}
								{item.name ? item.name : ''}
							</Link>
						</li>
					) : item.count ? (
						<div className="m-2 mt-6 py-3 pb-4" key={item.count}>
							<Icon name="wand-2"></Icon>
							<span className="ml-3 py-6 text-sm leading-6 text-pretty">
								{item.count}/100 AI requests
							</span>
						</div>
					) : (
						<div key="sep">
							{(tier === undefined ||
								!['enterprise', 'pro'].includes(tier)) && (
								<>
									<Separator />
									<div className="mt-2">
										<span className="mr-2 font-semibold">Pro Features</span>
										<ButtonLink to="/pricing" variant="outline">
											Upgrade
										</ButtonLink>
									</div>
								</>
							)}
						</div>
					),
				)}
		</ul>
	)
}
