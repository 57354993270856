import { type routeTypes } from '#app/root.tsx'
import { cn, Item } from '#app/utils/misc'
import { type Theme } from '#app/utils/theme.server.ts'
import { useProjectData } from '#app/utils/useProjectData.js'
import { useOptionalUser } from '#app/utils/user.ts'
import {
	Dialog,
	DialogPanel,
	Transition,
	TransitionChild,
} from '@headlessui/react'
import { Link, useLocation } from '@remix-run/react'
import { Fragment, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import Footer from './footer.tsx'
import { NavBar } from './nav-bar.tsx'
import { Logo } from './nav-logo.tsx'
import {
	internalNavItems,
	navItems,
	type NavItem,
} from './sidebar-items-data.tsx'
import { SidebarItems } from './sidebar-items.tsx'
import { Icon } from './ui/icon.tsx'
import { Separator } from './ui/separator.tsx'

export type RootUser = ReturnType<typeof useOptionalUser>

export function Layout({
	allProjects,
	children,
	routeType,
	theme,
	user,
}: {
	allProjects: Item[]
	children: React.ReactNode
	routeType: (typeof routeTypes)[number]
	theme: null | Theme
	user?: null | RootUser
}) {
	const [sidebarOpen, setSidebarOpen] = useState(false)
	const location = useLocation()
	const project = useProjectData()

	let appNavItems: NavItem[]

	// always show the navbar
	// but only show pricing+contact us if on marketing site

	// if logged in, show the project switcher (or just show the user dropdown)

	// if screen is small enough, show the sidebar
	// if logged in and on internal page, show all the app stuff

	if (['auth', 'marketing', 'other'].includes(routeType)) {
		appNavItems = navItems.marketing
	} else if (routeType === 'internal') {
		appNavItems = internalNavItems(project?.id ?? '')
	} else {
		appNavItems = navItems.marketing
	}

	const navItemsWithCurrent = appNavItems.map((item) => ({
		...item,
		current: item.href
			? item.href === '/'
				? location.pathname === item.href
				: location.pathname.startsWith(item.href)
			: false,
	}))

	return (
		<>
			<div className="h-full">
				<NavBar
					isLoggedIn={!!user}
					routeType={routeType}
					setSidebarOpen={setSidebarOpen}
					theme={theme}
				/>
				<Transition as={Fragment} show={sidebarOpen}>
					<Dialog
						as="div"
						className="relative z-50 xl:hidden"
						onClose={setSidebarOpen}
					>
						<TransitionChild
							as={Fragment}
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-black/10" />
						</TransitionChild>

						<div className="fixed inset-0 flex">
							<TransitionChild
								as={Fragment}
								enter="transition ease-in-out duration-300 transform"
								enterFrom="-translate-x-full"
								enterTo="translate-x-0"
								leave="transition ease-in-out duration-300 transform"
								leaveFrom="translate-x-0"
								leaveTo="-translate-x-full"
							>
								<DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1 ">
									<TransitionChild
										as={Fragment}
										enter="ease-in-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-300"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute left-full top-0 flex w-16 justify-center pt-5">
											<button
												className="-m-2.5 p-2.5"
												onClick={() => setSidebarOpen(false)}
												type="button"
											>
												<span className="sr-only">Close sidebar</span>
												<Icon
													aria-hidden="true"
													className="text-primary"
													name="x"
													size="lg"
												/>
											</button>
										</div>
									</TransitionChild>
									{/* Sidebar component, swap this element with another sidebar if you like */}
									<MainArea
										allProjects={allProjects}
										navItems={navItemsWithCurrent}
										routeType={routeType}
										setSidebarOpen={setSidebarOpen}
										user={user}
									/>
								</DialogPanel>
							</TransitionChild>
						</div>
					</Dialog>
				</Transition>

				{/* Static sidebar for desktop */}
				<div
					className={cn(
						['internal'].includes(routeType) &&
							'hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-60 xl:flex-col',
					)}
				>
					{['internal'].includes(routeType) && (
						<MainArea
							allProjects={allProjects}
							navItems={navItemsWithCurrent}
							routeType={routeType}
							setSidebarOpen={setSidebarOpen}
							user={user}
						/>
					)}
				</div>
				<div className={cn(routeType === 'internal' && 'h-full xl:pl-60')}>
					<main
						className={twMerge(
							'h-full',
							['internal'].includes(routeType) &&
								'px-4 py-2 sm:py-3 sm:px-6 lg:px-8',
						)}
					>
						{children}
					</main>
					{routeType !== 'internal' && (
						<div className=" pt-8 sm:pt-24 lg:px-8 lg:pt-32">
							<Separator className="w-full" />
							<Footer />{' '}
						</div>
					)}
				</div>
			</div>
		</>
	)
}

function MainArea({
	allProjects,
	navItems,
	routeType,
	setSidebarOpen,
}: {
	allProjects: Item[]
	navItems: NavItem[]
	routeType?: 'internal' | string
	setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>
	user?: null | RootUser
}) {
	const project = useProjectData()
	const user = useOptionalUser()
	return (
		<div
			className="flex grow flex-col gap-y-5 overflow-y-auto bg-background px-4 pt-4 ring-1 ring-white/10"
			onClick={() => {
				setSidebarOpen(false)
			}}
		>
			<div className="-mt-0.5">
				<Link to={routeType === 'internal' ? '/dashboard' : '/'}>
					<Logo />
				</Link>
			</div>
			<nav className="flex flex-1 flex-col">
				<SidebarItems
					allProjects={allProjects}
					navItems={navItems}
					project={project}
					setSidebarOpen={setSidebarOpen}
					tier={user?.tenant.tier ?? ''}
				/>
			</nav>
		</div>
	)
}
