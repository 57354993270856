import { type ModelName } from '#app/utils/modelNames.ts'
import { models } from '#app/utils/models.ts'
import { type IconName } from './ui/icon.tsx'

export interface NavItem {
	count?: number
	current?: boolean
	href: null | string
	icon: IconName | null
	inProject?: boolean
	name: string
}

// marketing
const marketing = [
	{ href: '/', icon: 'home', name: 'Home' },
	// { href: '/features', icon: 'envelope-closed', name: 'Features' },
	{ href: '/pricing', icon: 'camera', name: 'Pricing' },
	{ href: '/contact', icon: 'envelope-closed', name: 'Contact' },
	{ href: '/blog', icon: 'newspaper', name: 'Blog' },
	{ href: '/login', icon: 'log-in', name: 'Login' },
	{ href: '/signup', icon: 'user-plus', name: 'Try BrainBuild' },
] satisfies NavItem[]

const internalModels: ModelName[] = [
	'persona',
	'pain',
	'feature',
	'page',
	'process',
	// 'brand',
	'model',
]

export function internalNavItems(projectId: string): NavItem[] {
	const items: NavItem[] = internalModels.map((modelName) => {
		const model = models[modelName]
		return {
			href: model.inProject ? model.listUrl(projectId) : model.listUrl(),
			icon: 'icon' in model ? model.icon : 'check',
			inProject: true,
			name: model.displayNames.plural,
		}
	})

	return items
}

// all
export const navItems = {
	auth: marketing,
	marketing,
	other: marketing,
}
